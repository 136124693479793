import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {Link} from "react-router-dom";
import Input from "../common/Input";
import {Button, Row, Col, Modal} from "react-bootstrap";
import Dropdown from "../primer/Dropdown";
import "./index.scss";
import {login, logout} from "../../actions/auth";
import axios from "axios";
import {getToken, getURLParams, isEmpty} from "../common/commonFunctions";
import {toast} from "react-toastify";
import {LOGIN_IDENTIFICATION} from "../../config/configuration";

const Login = () => {
    const apiURL = process.env.REACT_APP_API_URL2;
    const [showPassword, setShowPassword] = useState(false);
    const [isCapsLockOn, setIsCapsLockOn] = useState(false);
    const [companyData, setCompanyData] = useState([]);
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showButton, setShowButton] = useState(false);
    let allowLogout = true
    const [tableData, setTableData] = useState(companyData);
    const [selectedData, setSelectedData] = useState({
        year: new Date().getFullYear(),
        _id: "",
        name: "",
        vatNumber: "",
    })
    const [companyFilterInput, setCompanyFilterInput] = useState("");
    const [doubleClicked, setDoubleClicked] = useState(false);

    const [email, setEmail] = useState("");
    const [emailWarning, setEmailWarning] = useState(false);
    const [emailFirstFocus, setEmailFirstFocus] = useState(false);
    const [password, setPassword] = useState("");
    const [passwordWarning, setPasswordWarning] = useState(false);
    const [passwordFirstFocus, setPasswordFirstFocus] = useState(false);

    useEffect(() => {
        const params = getURLParams();
        const redirect = params.get("redirect");
        const errorMessage = params.get("error");
        const message = params.get("message");
        if (redirect) localStorage.clear();

        if (errorMessage) {
            toast.error(errorMessage);
            window.history.pushState({}, "", window.location.origin); // Do not touch. Removes huge url and fixes change company
        }
        if (message) {
            toast.success(message);
            window.history.pushState({}, "", window.location.origin); // Do not touch. Removes huge url and fixes change company
        }
        if (localStorage.getItem("token")) {
            getCompanies(selectedData).then(r => handleShowFooterModal());
        }
    }, []);

    useEffect(() => {
        if (doubleClicked) {
            setDoubleClicked(false);
            submitCompany();
        }
    }, [doubleClicked])

    useEffect(() => {
        checkForEpayUser();
        checkForEmployee();
    }, [companyData]);

    useEffect(() => {
        if (emailFirstFocus) {
            if (isEmpty(email) && !emailWarning) {
                setEmailWarning(true);
            } else if (!isEmpty(email) && emailWarning) {
                setEmailWarning(false);
            }
        }
    }, [email])

    useEffect(() => {
        if (passwordFirstFocus) {
            if (isEmpty(password) && !passwordWarning) {
                setPasswordWarning(true);
            } else if (!isEmpty(password) && passwordWarning) {
                setPasswordWarning(false);
            }
        }
    }, [password])

    const companyYearOptions = (start = 2016, end = "") => {
        const startYear = start === "" ? "2016" : start;
        const endYear = end === "" ? new Date().getFullYear() : end;

        let options = [];
        for (let year = endYear; year >= startYear; year--) {
            options.push({label: year, value: year, color: "#76AEB7"});
        }

        return options;
    }

    const checkCapsLock = (event) => {
        if (event.getModifierState("CapsLock")) {
            setIsCapsLockOn(true);
        } else {
            setIsCapsLockOn(false);
        }
    }
    const handlePassword = () => {
        setShowPassword(!showPassword);
    }

    const handleFooterModalClose = () => setShow(false);
    const handleShowFooterModal = () => setShow(true);
    const handleFooterModalCloseConfirmation = () => setShowConfirmation(false);
    const handleShowFooterModalConfirmation = () => setShowConfirmation(true);

    const getCompanies = async (selectedData) => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            if (user.userType === 'Admin') {
                await axios
                    .get(apiURL + `/admin/company/list?year=${selectedData.year}`, {
                        headers: {Authorization: `Bearer ${localStorage.getItem("token")}`},
                    })
                    .then((res) => {
                        if (res.status === 200 && res.data?.success) {
                            setCompanyData(res.data.data);
                            setTableData(res.data.data);
                        } else {
                            localStorage.clear();
                            dispatch(logout());
                            handleFooterModalClose()
                            setShowButton(false);
                            if (!res.data?.success) {
                                toast.error("Service is unavailable for the moment. Please try again later.");
                            }
                        }
                    })
                    .catch((err) => {
                        if (err) {
                            localStorage.clear();
                            dispatch(logout());
                            handleFooterModalClose()
                            setShowButton(false);
                            console.log(err.response)
                            if (err.response?.status !== 401) {
                                toast.error("Service is unavailable for the moment. Please try again later.");
                            }
                        }
                    })
            } else {
                await axios
                    .get(apiURL + `/user/company/list?year=${selectedData.year}`, {
                        headers: {Authorization: `Bearer ${localStorage.getItem("token")}`}, maxBodyLength: Infinity,
                        maxContentLength: Infinity,
                    })
                    .then((res) => {
                        console.log(res.data.data.length)
                        if (res.status === 200 && res.data.data.length > 0 && res.data?.success) {
                            setCompanyData(res.data.data);
                            setTableData(res.data.data);
                        } else {
                            localStorage.clear();
                            dispatch(logout());
                            handleFooterModalClose()
                            setShowButton(false);
                            if (!res.data?.success) {
                                toast.error("Service is unavailable for the moment. Please try again later.");
                            }
                        }
                    })
                    .catch((err) => {
                        if (err) {
                            localStorage.clear();
                            dispatch(logout());
                            handleFooterModalClose()
                            setShowButton(false);
                            console.log(err)
                            if (err.response?.status !== 401) {
                                toast.error("Service is unavailable for the moment. Please try again later.");
                            }
                        }
                    })
            }
        } else {
            localStorage.clear();
            dispatch(logout());
            handleFooterModalClose()
            setShowButton(false);
        }
    }

    const checkForEpayUser = async () => {
        const user = JSON.parse(localStorage.getItem("user"));
        if (user?.permissions?.length > 0) {
            if (user.permissions.some((el) => ["custom-epay", "custom-peiraios", "custom-daras", "custom-cardlink"].includes(el.permissionName))) {
                setShow(false);
                const idx = companyData.findIndex((el) => el.vatNumber === "800566476");
                const comp = companyData[idx]
                const year = Number(new Date().getFullYear());
                const ip = comp.years.filter((el) => String(el.year) === String(year))[0].erp_ip;
                const selectedData = {
                    year: year,
                    _id: comp._id,
                    name: comp.name,
                    vatNumber: comp.vatNumber
                }
                await sessionStorage.setItem("company", JSON.stringify(selectedData));
                window.location.replace(`${ip}?token=${localStorage.getItem("token")}&company=${JSON.stringify(selectedData)}&device_id=${localStorage.getItem("deviceId")}&erp_ip=${ip}`);
            }
        }
    }

    const checkForEmployee = async () => {
        const user = JSON.parse(localStorage.getItem("user"));
        if (user?.userType === "Employee") {
            if (companyData?.length === 1) {
                setShow(false);
                const comp = companyData[0];
                const year = Number(new Date().getFullYear());
                const ip = comp.years.filter((el) => String(el.year) === String(year))[0].erp_ip;
                const selectedData = {
                    year: year,
                    _id: comp._id,
                    name: comp.name,
                    vatNumber: comp.vatNumber
                }
                await sessionStorage.setItem("company", JSON.stringify(selectedData));
                window.location.replace(`${ip}?token=${localStorage.getItem("token")}&company=${JSON.stringify(selectedData)}&device_id=${localStorage.getItem("deviceId")}&erp_ip=${ip}`);
            }
        }
    }

    const handleLogout = () => {
        const user = JSON.parse(localStorage.getItem('user'));
        setSelectedData({
            year: new Date().getFullYear(),
            _id: "",
            name: "",
            vatNumber: "",

        })
        axios.interceptors.request.use(
            (request) => {
                const token = getToken()
                if (token) {
                    request.headers['Authorization'] = 'Bearer ' + token
                }
                return request
            },
            (error) => {
                Promise.reject(error)
            }
        )
        if (!user?.userType) {
            console.log(user)
            localStorage.clear();
            dispatch(logout());
            toast.success('Επιτυχής Αποσύνδεση!')
            handleFooterModalClose()
            setShowButton(false);
        } else if (user.userType === 'Admin') {
            axios.post(apiURL + `/admin/auth/logout`)
                .then((res) => {
                    if (res.data.success === true) {
                        localStorage.clear();
                        dispatch(logout());
                        toast.success('Επιτυχής Αποσύνδεση!')
                        handleFooterModalClose()
                        setShowButton(false);
                    } else {
                        // toast.error(res.data.message)
                        localStorage.clear();
                        dispatch(logout());
                        toast.success('Επιτυχής Αποσύνδεση!')
                        handleFooterModalClose()
                        setShowButton(false);
                    }
                })
                .catch((err) => {
                    if (err) {
                        localStorage.clear();
                        dispatch(logout());
                        toast.success('Logout Επιτυχής Αποσύνδεση!')
                        handleFooterModalClose()
                        setShowButton(false);
                    }
                });
        } else {
            axios.post(apiURL + `/user/auth/logout`)
                .then((res) => {
                    if (res.data.success === true) {
                        localStorage.clear();
                        dispatch(logout());
                        toast.success('Επιτυχής Αποσύνδεση!')
                        handleFooterModalClose()
                        setShowButton(false);
                    } else {
                        // toast.error(res.data.message)
                        localStorage.clear();
                        dispatch(logout());
                        toast.success('Επιτυχής Αποσύνδεση!')
                        handleFooterModalClose()
                        setShowButton(false);
                    }
                })
                .catch((err) => {
                    if (err) {
                        localStorage.clear();
                        dispatch(logout());
                        toast.success('Επιτυχής Αποσύνδεση!')
                        handleFooterModalClose()
                        setShowButton(false);
                    }
                });
        }
    }

    const submitCompany = async () => {
        const user = JSON.parse(localStorage.getItem('user'));
        let erpURLSend = "";
        let erpBack = "";
        if (user.userType === "Admin" || (user?.canAccessExpired && user.canAccessExpired === "active")) {
            if (selectedData._id) {
                for (let i = 0; i < companyData.length; i++) {
                    companyData[i].years.forEach((y, j) => {
                        if ((y.year === selectedData.year || y.year === selectedData.year.toString()) && selectedData._id === companyData[i]._id) {
                            erpURLSend = y.erp_ip;
                            erpBack = y.erp_ip;
                        }
                    });
                }
                await sessionStorage.setItem("company", JSON.stringify(selectedData));
                setTimeout(() => {
                    window.location.replace(`${erpURLSend}?token=${localStorage.getItem("token")}&company=${JSON.stringify(selectedData)}&device_id=${localStorage.getItem("deviceId")}&erp_ip=${erpBack}`);
                }, 100)
            } else {
                toast.error("Please Select Company");
            }
        } else {
            //checkExpired
            let requestData = {
                company: selectedData._id
            }
            await axios.post(apiURL + `/public/company/check-company-expiration`, requestData)
                .then(async response => {
                    if (response.data && response.data.success === true) {
                        if (selectedData._id) {
                            for (let i = 0; i < companyData.length; i++) {
                                companyData[i].years.forEach((y, j) => {
                                    if ((y.year === selectedData.year || y.year === selectedData.year.toString()) && selectedData._id === companyData[i]._id) {
                                        erpURLSend = y.erp_ip;
                                        erpBack = y.erp_ip;
                                    }
                                });
                            }
                            await sessionStorage.setItem("company", JSON.stringify(selectedData));
                            window.location.replace(`${erpURLSend}?token=${localStorage.getItem("token")}&company=${JSON.stringify(selectedData)}&device_id=${localStorage.getItem("deviceId")}&erp_ip=${erpBack}`);
                        } else {
                            toast.error("Please Select Company");
                        }
                    } else {
                        toast.error("Η Εταιρία έχει λήξει!");
                    }
                })
                .catch(error => {
                    console.log(error);
                })
        }
    }

    const submitLoginTrue = async () => {
        allowLogout = true
        dispatch(
            login(
                email,
                password,
                handleShowFooterModal,
                allowLogout,
                handleShowFooterModalConfirmation
            )
        )
            .then(() => {
                getCompanies(selectedData);
                handleFooterModalCloseConfirmation();
            })
            .catch(() => {
                // setLoading(false);
            });
    }

    const handleYearChange = (e) => {
        const user = JSON.parse(localStorage.getItem('user'));
        setSelectedData({...selectedData, ...{year: e.value}});
        if (user.userType === 'Admin') {
            axios.get(apiURL + `/admin/company/list?year=${e.value}`, {
                headers: {Authorization: `Bearer ${localStorage.getItem("token")}`},
            })
            .then((res) => {
                if (res.status === 200) {
                    setCompanyData(res.data.data);
                }
            })
            .catch((err) => {
                console.log(err);
            })
        } else {
            axios.get(apiURL + `/user/company/list?year=${e.value}`, {
                headers: {Authorization: `Bearer ${localStorage.getItem("token")}`},
            })
            .then((res) => {
                if (res.status === 200) {
                    setCompanyData(res.data.data);
                }
            })
            .catch((err) => {
                console.log(err);
            })
        }
    }

    useEffect(() => {
        filterCompanyData(companyFilterInput);
    }, [companyData])

    useEffect(() => {
        filterCompanyData(companyFilterInput);
    }, [companyFilterInput])

    const filterCompanyData = (value) => {
        if (value !== "") {
            let filteredData = [];
            let value = companyFilterInput.toString().toLowerCase();
            const pattern = new RegExp(value, "gi");
            filteredData = companyData.filter((item) => {
                let returnType = false;
                for (const key in item) {
                    if (key === "name" || key === "vatNumber") {
                        let string = item[key].toString().replace(/\s/g, '')
                        if (pattern.test(string)) {
                            returnType = true;
                        }
                    }
                }
                return returnType;
            });
            setTableData(filteredData);
        } else {
            setTableData(companyData);
        }
    }

    const handleCompanySelection = (company, doubleClick) => {
        if (!doubleClick) {
            setShowButton(true);
            setSelectedData({...selectedData, ...company});
        } else {
            setShowButton(false);
            setSelectedData({...selectedData, ...company});
            setDoubleClicked(true);
        }
    }

    const isSelected = (vatNumber) => {
        return vatNumber === selectedData.vatNumber;
    }

    const attemptLogin = () => {
        if (isEmpty(email) || isEmpty(password)) {
            return toast.error("Παρακαλώ συμπληρώστε όνομα χρήστη/e-mail και κωδικό.");
        }
        dispatch(login(email, password, handleShowFooterModal, allowLogout, handleShowFooterModalConfirmation))
            .then(() => {
                getCompanies(selectedData);
            })
            .catch((err) => console.log(err));
    }

    const tdStyle = {
        padding: "7px",
        borderLeft: "1px solid lightgray",
        borderTop: "1px solid lightgray",
        borderRight: "1px solid lightgray",
        borderBottom: "1px solid black",
    }

    const showPasswordStyle = {
        position: "absolute",
        top: "30%",
        right: "5%",
        zIndex: "1000",
        cursor: "pointer",
    }

    return (
        <React.Fragment>
            <Row className="row align-item-center justify-content-center" style={{marginTop: "19vh"}} onKeyDown={(e) => {
                if (e.key === "Enter") attemptLogin()}
            }>
                <Col md={7}>
                    <div className={"authincation-content"}>
                        <Row>
                            <Col xl={12}>
                                <div className="login-banner-txt"><h4 className="text-center">Συνδεθείτε στον λογαριασμό σας</h4></div>
                                <div className="auth-form">
                                    <img
                                        src={"assets/images/prrimer-logo.png"}
                                        className="img-fluid login-logo-center"
                                        alt="logo"
                                    />
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend"><span className="input-group-text"><i className="fa fa-user"></i></span></div>
                                        <input
                                            onChange={(e) => setEmail(e.target.value)}
                                            onBlur={() => {
                                                if (!emailFirstFocus) {
                                                    setEmailFirstFocus(true);
                                                }
                                            }}
                                            value={email}
                                            id="email"
                                            type="text"
                                            autoComplete="off"
                                            name="email"
                                            placeholder="Όνομα Χρήστη ή email"
                                            className={"form-control"}
                                        />
                                    </div>
                                    {emailWarning && (
                                        <div className="input-feedback mb-1">
                                            Απαιτείται Όνομα Χρήστη ή email
                                        </div>
                                    )}
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend"><span className="input-group-text"><i className="fa fa-lock"></i></span>
                                        </div>
                                        <input
                                            onChange={(e) => setPassword(e.target.value)}
                                            onBlur={() => {
                                                if (!passwordFirstFocus) {
                                                    setPasswordFirstFocus(true);
                                                }
                                            }}
                                            value={password}
                                            id="password"
                                            autoComplete="off"
                                            onKeyUp={checkCapsLock}
                                            type={showPassword ? "text" : "password"}
                                            name="password"
                                            placeholder="κωδικός"
                                            className={"form-control"}
                                        />
                                        {!showPassword ? (
                                            <i
                                                className="fa fa-eye-slash"
                                                style={showPasswordStyle}
                                                onClick={handlePassword}
                                            ></i>
                                        ) : (
                                            <i
                                                className="fa fa-eye"
                                                style={showPasswordStyle}
                                                onClick={handlePassword}
                                            ></i>
                                        )}
                                    </div>
                                    {passwordWarning && (
                                        <div className="input-feedback mb-2">
                                            Απαιτείται κωδικός
                                        </div>
                                    )}
                                    {isCapsLockOn && (
                                        <div className="input-feedback mb-1">
                                            Προειδοποίηση: Χρησιμοποιείτε κεφαλαία γράμματα
                                        </div>
                                    )}
                                    {LOGIN_IDENTIFICATION !== "EMRON" && (
                                        <div className="form-row float-left">
                                            <div className="form-group">
                                                <Link to="/register" className="forgot-password">
                                                    Δεν έχετε λογαριασμό; Εγγραφή
                                                </Link>
                                            </div>
                                        </div>
                                    )}
                                    <div className="form-row float-right">
                                        <div className="form-group">
                                            <Link to="/forgot-password" className="forgot-password">
                                                Ξεχάσατε τον κωδικό;
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <button className="btn btn-primary btn-block float-left mb-4 pt-3" onClick={() => attemptLogin()}>ΕΙΣΟΔΟΣ</button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>

            <Modal className="themeModal" show={show} onHide={handleFooterModalClose}
                   dialogClassName={"modal42PercentWidth"} backdrop={"static"}>
                <Modal.Header>
                    <Modal.Title>Επιλέξτε Εταιρεία και Έτος</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="pb-3">
                        <Col md={9}>
                            <Input
                                type="text"
                                name="search"
                                label={"Αναζήτηση"}
                                placeholder="Αναζήτηση με όνομα ή Α.Φ.Μ..."
                                value={companyFilterInput}
                                onChange={(e) => setCompanyFilterInput(e.target.value)}
                            />
                        </Col>
                        <Col md={3}>
                            <Dropdown
                                label={"Έτος"}
                                defaultValue={{
                                    label: selectedData.year,
                                    value: selectedData.year,
                                }}
                                options={companyYearOptions()}
                                searchEnable={false}
                                classes="w-100"
                                onChange={(e) => handleYearChange(e)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <div style={{maxHeight: "55vh", overflowY: "auto"}}>
                                <table style={{width: "100%"}}>
                                    <colgroup>
                                        <col span={1} style={{width: "80%"}}></col>
                                        <col span={1} style={{width: "20%"}}></col>
                                    </colgroup>
                                    <thead style={{
                                        textAlign: "center",
                                        backgroundColor: "var(--light-skyblue)",
                                        position: "sticky",
                                        top: "-1px"
                                    }}>
                                    <tr>
                                        <th style={tdStyle}>Εταιρεία</th>
                                        <th style={tdStyle}>Α.Φ.Μ.</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {tableData?.length > 0 ? (
                                        <React.Fragment>
                                            {tableData.map((company, idx) => (
                                                <tr key={`companyModalTable-${idx}`}
                                                    onClick={() => handleCompanySelection(company, false)}
                                                    onDoubleClick={() => handleCompanySelection(company, true)}
                                                    style={{
                                                        backgroundColor: isSelected(company.vatNumber) ? "var(--light-skyblue)" : "",
                                                        border: isSelected(company.vatNumber) ? "2px solid black" : ""
                                                    }}>
                                                    <td style={tdStyle}>{company.name}</td>
                                                    <td style={{
                                                        ...tdStyle,
                                                        textAlign: "center"
                                                    }}>{company.vatNumber}</td>
                                                </tr>
                                            ))}
                                        </React.Fragment>
                                    ) : (
                                        <tr>
                                            <td colSpan={2}>Δεν βρέθηκαν δεδομένα.</td>
                                        </tr>
                                    )}
                                    </tbody>
                                </table>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="mr-auto"
                            variant="outline-primary"
                            onClick={() => handleLogout()}
                    >
                        Αποσύνδεση
                    </Button>
                    {showButton === true && (
                        <Button
                            variant="primary"
                            onClick={() => submitCompany()}
                        >
                            Επιλογή
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>

            <Modal className="themeModal" show={showConfirmation}>
                <Modal.Header>
                    <Modal.Title>Επιβεβαίωση</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        {" "}
                        <text style={{textAlign: "center", fontSize: "25px"}}>
                            Ο χρήστης είναι ήδη συνδεδεμένος σε άλλη συσκευή. Θα αποσυνδεθείτε αυτόματα από την άλλη
                            συσκευή.
                        </text>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="outline-primary"
                        onClick={() => handleFooterModalCloseConfirmation()}
                    >
                        Όχι
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => submitLoginTrue()}
                    >
                        Ναι
                    </Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

export default Login;
